// import React, { useState } from "react";
// import {
//   Grid,
//   Paper,
//   Card,
//   CardContent,
//   Typography,
//   Button as MuiButton,
//   TextField,
//   Autocomplete,
// } from "@mui/material";
// import { useFormik } from "formik";
// import Notification from "../../core/Notification"; // Assuming Notification is available
// import Menu from "../../core/Menu"; // Menu component included
// import PageHeader from "../../core/PageHeader"; // PageHeader included
// import { API_URL } from "../../redux/constants/apiConstants";
// import axios from "axios";

// const useStyles = () => ({
//   card: {
//     margin: "16px",
//     padding: "16px",
//     textAlign: "center",
//     backgroundColor: "#f9f9f9",
//   },
//   formContainer: {
//     padding: "16px",
//     marginBottom: "16px",
//     backgroundColor: "#fff",
//   },
// });

// const SummaryCardView = () => {
//   const classes = useStyles();
//   const [data, setData] = useState([]);
//   const [notify, setNotify] = useState({
//     isOpen: false,
//     message: "",
//     type: "",
//   });

//   const fetchSummaryData = async (values) => {
//     const axios_config = {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem("token")}`, // Replace with your token logic
//         "Content-Type": "application/json",
//       },
//     };

//     const api_endpoint = `${API_URL}/Report/GetSummarySalesOrPayment?startDate=${values.startDate}&endDate=${values.endDate}&intervalType=${values.intervalType}&dataType=${values.dataType}`;

//     try {
//       const response = await axios.get(api_endpoint, axios_config);
//       const { data } = response;

//       if (data?.statusCode === 200 && data?.dataObj?.length > 0) {
//         setData(data.dataObj);
//         setNotify({
//           isOpen: true,
//           message: "Data fetched successfully.",
//           type: "success",
//         });
//       } else {
//         setData([]);
//         setNotify({
//           isOpen: true,
//           message: "No data found.",
//           type: "warning",
//         });
//       }
//     } catch (error) {
//       setNotify({
//         isOpen: true,
//         message: "Error fetching data.",
//         type: "error",
//       });
//     }
//   };

//   const formik = useFormik({
//     initialValues: {
//       startDate: new Date().toISOString().slice(0, 10),
//       endDate: new Date().toISOString().slice(0, 10),
//       intervalType: "daily", // Default value
//       dataType: "sales", // Default value
//     },
//     onSubmit: fetchSummaryData,
//   });

//   return (
//     <div>
//       {/* Menu and Page Header */}
//       <Menu />
//       <PageHeader
//         icon={<Typography variant="h6">📊</Typography>}
//         title="Summary Card View"
//         subtitle="Generate and view summary data in card format"
//       />

//       {/* Form Container */}
//       <Paper className={classes.formContainer}>
//         <form onSubmit={formik.handleSubmit}>
//           <Grid container spacing={2}>
//             <Grid item xs={12} md={3}>
//               <TextField
//                 label="Start Date"
//                 type="date"
//                 name="startDate"
//                 value={formik.values.startDate}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={12} md={3}>
//               <TextField
//                 label="End Date"
//                 type="date"
//                 name="endDate"
//                 value={formik.values.endDate}
//                 onChange={formik.handleChange}
//                 InputLabelProps={{
//                   shrink: true,
//                 }}
//                 fullWidth
//               />
//             </Grid>
//             <Grid item xs={12} md={3}>
//               <Autocomplete
//                 size="small"
//                 options={["daily", "weekly", "monthly"]}
//                 getOptionLabel={(option) => option}
//                 value={formik.values.intervalType}
//                 onChange={(e, value) => formik.setFieldValue("intervalType", value)}
//                 renderInput={(params) => (
//                   <TextField {...params} label="Interval Type" fullWidth />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12} md={3}>
//               <Autocomplete
//                 size="small"
//                 options={["sales", "payment"]}
//                 getOptionLabel={(option) => option}
//                 value={formik.values.dataType}
//                 onChange={(e, value) => formik.setFieldValue("dataType", value)}
//                 renderInput={(params) => (
//                   <TextField {...params} label="Data Type" fullWidth />
//                 )}
//               />
//             </Grid>
//             <Grid item xs={12}>
//               <MuiButton
//                 type="submit"
//                 variant="contained"
//                 color="primary"
//                 fullWidth
//               >
//                 Fetch Data
//               </MuiButton>
//             </Grid>
//           </Grid>
//         </form>
//       </Paper>

//       {/* Card View */}
//       <Grid container spacing={2}>
//         {data.map((item, index) => (
//           <Grid item xs={12} sm={6} md={4} key={index}>
//             <Card className={classes.card}>
//               <CardContent>
//                 <Typography variant="h6" component="div">
//                   Interval: {item.intervalValue}
//                 </Typography>
//                 <Typography variant="h4" component="div">
//                   Amount: {item.amount.toLocaleString("en-US", {
//                     style: "currency",
//                     currency: "USD",
//                   })}
//                 </Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Notification */}
//       <Notification notify={notify} setNotify={setNotify} />
//     </div>
//   );
// };

// export default SummaryCardView;

import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, IconButton, Tooltip } from "@mui/material";
import { BarChart, ShowChart, TableChart, PieChart } from "@mui/icons-material";
import { Chart } from "react-google-charts";
import axios from "axios";
import { API_URL } from "../../redux/constants/apiConstants";
import Notification from "../../core/Notification";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";

const CustomerPaymentSales = () => {
  const [dailyData, setDailyData] = useState([]);
  const [weeklyData, setWeeklyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [chartType, setChartType] = useState("stacked"); // Track selected chart type

  const getDefaultRange = (intervalType) => {
    const currentDate = new Date();
    const startDate = new Date();

    if (intervalType === "daily") {
      startDate.setDate(currentDate.getDate() - 31);
    } else if (intervalType === "weekly") {
      startDate.setMonth(currentDate.getMonth() - 3);
    } else if (intervalType === "yearly") {
      startDate.setFullYear(currentDate.getFullYear() - 1);
    }

    return {
      start_date: startDate.toISOString().slice(0, 10),
      end_date: currentDate.toISOString().slice(0, 10),
    };
  };

  const fetchChartData = async () => {
    setLoading(true);
    try {
      const buildApiUrl = (startDate, endDate, intervalType, dataType) =>
        `${API_URL}/Report/GetSummarySalesOrPayment?startdate=${startDate}&enddate=${endDate}&intervalType=${intervalType}&dataType=${dataType}`;

      const ranges = {
        daily: getDefaultRange("daily"),
        weekly: getDefaultRange("weekly"),
        yearly: getDefaultRange("yearly"),
      };

      const [
        dailySales,
        dailyPayment,
        weeklySales,
        weeklyPayment,
        yearlySales,
        yearlyPayment,
      ] = await Promise.all([
        axios.get(buildApiUrl(ranges.daily.start_date, ranges.daily.end_date, "daily", "sales")),
        axios.get(buildApiUrl(ranges.daily.start_date, ranges.daily.end_date, "daily", "payment")),
        axios.get(buildApiUrl(ranges.weekly.start_date, ranges.weekly.end_date, "weekly", "sales")),
        axios.get(buildApiUrl(ranges.weekly.start_date, ranges.weekly.end_date, "weekly", "payment")),
        axios.get(buildApiUrl(ranges.yearly.start_date, ranges.yearly.end_date, "monthly", "sales")),
        axios.get(buildApiUrl(ranges.yearly.start_date, ranges.yearly.end_date, "monthly", "payment")),
      ]);

      const processData = (salesData, paymentData, intervalType) => {
        const salesMap = new Map(salesData.map((item) => [item.intervalValue, parseFloat(item.amount) || 0]));
        const paymentMap = new Map(paymentData.map((item) => [item.intervalValue, parseFloat(item.amount) || 0]));

        const allKeys = Array.from(
          new Set([...salesMap.keys(), ...paymentMap.keys()])
        ).sort((a, b) => {
          if (intervalType === "daily") {
            return new Date(a) - new Date(b);
          } else if (intervalType === "weekly") {
            const weekA = parseInt(a.replace("Week", "").trim(), 10);
            const weekB = parseInt(b.replace("Week", "").trim(), 10);
            return weekA - weekB;
          } else if (intervalType === "yearly") {
            return new Date(a) - new Date(b);
          }
          return 0;
        });

        return allKeys.map((key) => {
          const formattedKey =
            intervalType === "daily"
              ? new Date(key).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                })
              : key;
          return [formattedKey, salesMap.get(key) || 0, paymentMap.get(key) || 0];
        });
      };

      setDailyData(processData(dailySales.data.dataObj || [], dailyPayment.data.dataObj || [], "daily"));
      setWeeklyData(processData(weeklySales.data.dataObj || [], weeklyPayment.data.dataObj || [], "weekly"));
      setYearlyData(processData(yearlySales.data.dataObj || [], yearlyPayment.data.dataObj || [], "yearly"));

      setNotify({
        isOpen: true,
        message: "Data fetched successfully.",
        type: "success",
      });
    } catch (error) {
      setNotify({
        isOpen: true,
        message: "Error fetching data. Please check API parameters.",
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, []);

  const renderChart = (data, title) => {
    if (!data || data.length === 0) {
      return (
        <Typography variant="h6" align="center">
          No valid data available for {title}.
        </Typography>
      );
    }
  
    if (chartType === "stacked") {
      const chartData = [
        ["Interval", "Sales", "Payment"],
        ...data.map((item) => [item[0], item[1], item[2]]),
      ];
      return (
        <Chart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={chartData}
          options={{
            title,
            hAxis: { title: "Interval" },
            vAxis: { title: "Amount" },
            isStacked: true,
            legend: { position: "top" },
            colors: ["#4285F4", "#EA4335"],
          }}
        />
      );
    } else if (chartType === "line") {
      const chartData = [
        ["Interval", "Sales", "Payment"],
        ...data.map((item) => [item[0], item[1], item[2]]),
      ];
      return (
        <Chart
          chartType="LineChart"
          width="100%"
          height="400px"
          data={chartData}
          options={{
            title,
            hAxis: { title: "Interval" },
            vAxis: { title: "Amount" },
            legend: { position: "top" },
            pointSize: 5,
            colors: ["#4285F4", "#EA4335"],
          }}
        />
      );
    } else if (chartType === "bar") {
      const chartData = [
        ["Interval", "Sales", "Payment"],
        ...data.map((item) => [item[0], item[1], item[2]]),
      ];
      return (
        <Chart
          chartType="BarChart"
          width="100%"
          height="400px"
          data={chartData}
          options={{
            title,
            hAxis: { title: "Amount" },
            vAxis: { title: "Interval" },
            legend: { position: "top" },
            colors: ["#4285F4", "#EA4335"],
          }}
        />
      );
    } else if (chartType === "table") {
      return (
        <Paper style={{ padding: "16px", marginBottom: "16px" }}>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              fontSize: "16px",
            }}
          >
            <thead>
              <tr
                style={{
                  backgroundColor: "#f5f5f5",
                  fontWeight: "bold",
                }}
              >
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                  ID
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                  Interval
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                  Sales
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px", textAlign: "center" }}>
                  Payment
                </th>
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f9f9f9" : "#ffffff",
                  }}
                >
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {index + 1}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {item[0]}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {item[1].toLocaleString()}
                  </td>
                  <td
                    style={{
                      border: "1px solid #ddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    {item[2].toLocaleString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Paper>
      );
    }
  };
  
  return (
    <div>
      <Menu />
      <PageHeader
        icon={<Typography variant="h6">📊</Typography>}
        title="Customer Payment & Sales"
        subtitle="View sales and payment trends"
      />

<Paper style={{ padding: "16px", marginBottom: "16px" }}>
  <Typography variant="h6" align="center" style={{ marginBottom: "16px" }}>
    
  </Typography>
  <Grid container justifyContent="center" spacing={3}>
    <Grid item>
      <Tooltip title="Stacked Column Chart">
        <IconButton
          onClick={() => setChartType("stacked")}
          style={{
            color: chartType === "stacked" ? "#4285F4" : "#B0BEC5", // Blue when selected, gray otherwise
            fontSize: "36px",
          }}
        >
          <BarChart fontSize="large" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Line Chart">
        <IconButton
          onClick={() => setChartType("line")}
          style={{
            color: chartType === "line" ? "#EA4335" : "#B0BEC5", // Red when selected, gray otherwise
            fontSize: "36px",
          }}
        >
          <ShowChart fontSize="large" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Bar Chart">
        <IconButton
          onClick={() => setChartType("bar")}
          style={{
            color: chartType === "bar" ? "#34A853" : "#B0BEC5", // Green when selected, gray otherwise
            fontSize: "36px",
          }}
        >
          <PieChart fontSize="large" />
        </IconButton>
      </Tooltip>
    </Grid>
    <Grid item>
      <Tooltip title="Table">
        <IconButton
          onClick={() => setChartType("table")}
          style={{
            color: chartType === "table" ? "#FBBC05" : "#B0BEC5", // Yellow when selected, gray otherwise
            fontSize: "36px",
          }}
        >
          <TableChart fontSize="large" />
        </IconButton>
      </Tooltip>
    </Grid>
  </Grid>
</Paper>


      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h5" align="center">
          Daily Summary (Last 30 Days)
        </Typography>
        {renderChart(dailyData, "Daily Summary (Last 30 Days)")}
      </Paper>
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h5" align="center">
          Weekly Summary (Last 12 Weeks)
        </Typography>
        {renderChart(weeklyData, "Weekly Summary (Last 12 Weeks)")}
      </Paper>
      <Paper style={{ padding: "16px", marginBottom: "16px" }}>
        <Typography variant="h5" align="center">
          Yearly Summary (Last 1 Year)
        </Typography>
        {renderChart(yearlyData, "Yearly Summary (Last 1 Year)")}
      </Paper>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
};

export default CustomerPaymentSales;
