// Importing React and necessary hooks
import React, { useEffect, useState } from "react";
// Importing Redux hooks for state management
import { useSelector, useDispatch } from "react-redux";
// Importing MUI components for UI structure and icons
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, CircularProgress, Divider, Button } from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
// Importing custom components
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import Notification from "../../core/Notification";
// Importing Redux actions
import { getListPIByStatus } from "../../redux/actions/LcActions";
// Importing styling
import { useStyles } from "./lcStyle";
import { useHistory } from "react-router-dom";

const LcDocAndPaymentList = () => {
  const classes = useStyles(); // Custom styles hook
  const dispatch = useDispatch(); // Redux dispatch function
  const history = useHistory();

  // Retrieving state from Redux store
  const listPIByStatusState = useSelector((state) => state.listPIByAcitivityStatusState);
  const { loading, error, proformaInvoices } = listPIByStatusState;

  // Local state for notifications
  const [notify, setNotify] = useState({ isOpen: false, message: "", type: "" });

  // Fetching data on component mount
  useEffect(() => {
    dispatch(getListPIByStatus(3)); // Fetch data where status = 1
  }, [dispatch]);

  
  const handleCreateLC = (row) => {
    const { lcDocId, customerName } = row; // Extracting customerName
    history.push({
      pathname: `/lctracking/${lcDocId}/lcpayment`,
      state: { customerName }, // Passing customerName in the state
    });
  };
  
  
  // DataGrid column definitions
  const columns = [
    { field: "piSystemId", headerName: "PI System", flex: 1, headerAlign: "center", align: "center" },
    { field: "piNumber", headerName: "PI Number", flex: 1, headerAlign: "center", align: "center" },
    { field: "piDate", headerName: "PI Date", flex: 1, headerAlign: "center", align: "center" },
    { field: "customerName", headerName: "Customer Name", flex: 1, headerAlign: "center", align: "center" },
    { field: "lcNumber", headerName: "LC Number", flex: 2, headerAlign: "center", align: "center" },
    { field: "lcDate", headerName: "LC Date", flex: 2, headerAlign: "center", align: "center" },
    { field: "lcDocId", headerName: "LC Doc Id", flex: 2, headerAlign: "center", align: "center" },
    
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <Button
          variant="contained"
          //color="primary"
          style={{
            backgroundColor: "#32CD32", // Light green color
            color: "#ffffff",          // White text color
          }}
          size="small"
          onClick={() => handleCreateLC(params.row)} // Pass the entire row to handleCreateLC
        >
          Payment
        </Button>
      ),
    },
        
  ];

  return (
    <Box>
      {/* Menu and page header */}
      <Menu />
      <PageHeader icon={<PaymentIcon />} title="LC Documents and Payments" subtitle="List of Proforma Invoices (Status = 1)" />

      {/* Conditional rendering based on loading/error states */}
      {loading ? (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress color="primary" />
        </Box>
      ) : error ? (
        <Box display="flex" justifyContent="center" p={5}>
          <Typography variant="h6" color="error">
            Error loading data: {error}
          </Typography>
        </Box>
      ) : (
        <Box m={2} p={2} bgcolor="background.paper" borderRadius={2} boxShadow={3}>
          {/* Divider above the DataGrid */}
          <Divider sx={{ mb: 2 }} />

          <DataGrid
            rows={proformaInvoices}
            columns={columns}
            getRowId={(row) => row.piSystemId}
            pageSize={10}
            autoHeight
            rowsPerPageOptions={[10, 20, 100]}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#013220", // Replace with your desired header background color
                color: "#ffffff", // Replace with your desired header text color
                fontWeight: "bold", // Bold header text
                fontSize: "16px", // Adjust the font size
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "bold", // Ensure the text inside headers is bold
              },
            }}
          />

          {/* Notification component */}
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </Box>
  );
};
export default LcDocAndPaymentList;
