import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Button as MuiButton,
  CircularProgress,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory, useParams,useLocation } from "react-router-dom";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";

import { getDocumentDetails, updateDocument } from "../../redux/actions/LcActions";
import { formatDateForFrontend, formatDateForBackend } from "../../utils/dateUtils";
import { usePIStyles } from "./lcStyle";

const LCPaymentEntry = () => {
  const classes = usePIStyles(); // Custom styles hook
  const dispatch = useDispatch(); // Redux dispatch function
  const history = useHistory(); // Hook for navigation
  const { id: lcDocId } = useParams(); // Fetch LC Document ID from route params
  const location = useLocation();
  const { customerName } = location.state || {};
  const documentDetailsState = useSelector((state) => state.documentDetailsState);
  const { loading: documentLoading, error, document } = documentDetailsState;

  const [loading, setLoading] = useState(false);

  // Fetch existing document details
  useEffect(() => {
    dispatch(getDocumentDetails(lcDocId));
  }, [dispatch, lcDocId]);

  // Formik setup
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      lcPaymentDate: document?.lcPaymentDate
        ? formatDateForFrontend(document.lcPaymentDate)
        : "",
      lcReceivedAmount: document?.lcReceivedAmount || "",
      lcConversionRateCollection: document?.lcConversionRateCollection || "",
      aitCollectionDate: document?.aitCollectionDate
        ? formatDateForFrontend(document.aitCollectionDate)
        : "",
      aitAmount: document?.aitAmount || "",
    },
    onSubmit: async (values) => {
      setLoading(true);

      // Create the payload with updated and untouched fields
      const payload = {
        ...document,
        lcPaymentDate: values.lcPaymentDate
          ? formatDateForBackend(values.lcPaymentDate)
          : document.lcPaymentDate,
        lcReceivedAmount: values.lcReceivedAmount || document.lcReceivedAmount,
        lcConversionRateCollection:
          values.lcConversionRateCollection || document.lcConversionRateCollection,
        aitCollectionDate: values.aitCollectionDate
          ? formatDateForBackend(values.aitCollectionDate)
          : document.aitCollectionDate,
        aitAmount: values.aitAmount || document.aitAmount,
      };

      try {
        const response = await dispatch(updateDocument(payload));
        setLoading(false);
        if (response?.statusCode === 200 || response?.statusCode === 205) {
          history.push("/lctracking/lcpaymentlist");
        } else {
          alert(response?.message || "Update failed. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        alert("An unexpected error occurred. Please try again later.");
      }
    },
  });

  return (
    <div>
      {/* Menu and Page Header */}
      <Menu />
      <PageHeader title="LC Payment Entry" />

      <Paper className={classes.pageContent}>
        {documentLoading ? (
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress color="primary" />
          </Box>
        ) : error ? (
          <Box display="flex" justifyContent="center" p={5}>
            <Typography color="error" variant="h6">
              Error: {error}
            </Typography>
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {/* Header Section */}
            <Box p={3} mb={4} borderRadius={2} border={1} borderColor="grey.300">
              <Typography variant="h6" gutterBottom>
                LC Related Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>PI System ID: {document?.piSystemId || "N/A"}</Typography>
                </Grid>
                 <Grid item xs={12} sm={6}>
                                  <Typography>Customer Name: {customerName || "N/A"}</Typography>
                                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>PI Date: {document?.piDate || "N/A"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>LC Number: {document?.lcNumber || "N/A"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>LC Date: {document?.lcDate || "N/A"}</Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Form Section */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="LC Payment Date"
                  type="date"
                  name="lcPaymentDate"
                  value={formik.values.lcPaymentDate}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="LC Received Amount"
                  name="lcReceivedAmount"
                  value={formik.values.lcReceivedAmount}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="LC Conversion Rate"
                  name="lcConversionRateCollection"
                  value={formik.values.lcConversionRateCollection}
                  onChange={formik.handleChange}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="AIT Collection Date"
                  type="date"
                  name="aitCollectionDate"
                  value={formik.values.aitCollectionDate}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="AIT Amount"
                  name="aitAmount"
                  value={formik.values.aitAmount}
                  onChange={formik.handleChange}
                />
              </Grid>
            </Grid>

            {/* Submit and Back Buttons */}
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12}>
                <MuiButton
                  size="large"
                  type="submit"
                  disabled={loading}
                  endIcon={loading ? <CircularProgress size="1rem" /> : null}
                >
                  {loading ? "Updating..." : "Save Payment Info"}
                </MuiButton>
                <MuiButton
                  size="large"
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => history.push("/lctracking/lcpaymentlist")}
                >
                  Back to List
                </MuiButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </div>
  );
};

export default LCPaymentEntry;
