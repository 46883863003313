// utils/dateUtils.js
export const formatDate = (date) => {
    if (!date) return "";
    const utcDate = new Date(date);
    // Adjust for timezone differences
    utcDate.setMinutes(utcDate.getMinutes() + utcDate.getTimezoneOffset());
    return utcDate.toISOString().split("T")[0];
  };
  /**
 * Format a Date object or ISO string into "YYYY-MM-DD" format for backend.
 * @param {string|Date} date - A Date object or ISO string.
 * @returns {string} Formatted date in "YYYY-MM-DD".
 */
export const formatDateForBackend = (date) => {
  if (!date) return ""; // Handle null or undefined dates
  const isoDate = new Date(date).toISOString();
  return isoDate.split("T")[0]; // Extract only the date part
};

/**
* Format an ISO string or Date object into "YYYY-MM-DD" for frontend display.
* @param {string|Date} isoDateString - A Date object or ISO string.
* @returns {string} Formatted date in "YYYY-MM-DD".
*/
export const formatDateForFrontend = (isoDateString) => {
  if (!isoDateString) return ""; // Handle null or undefined dates
  const date = new Date(isoDateString);
  const year = date.getFullYear();
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const day = ("0" + date.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
