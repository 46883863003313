import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Button as MuiButton,
  CircularProgress,
  TextField,
  Typography,
  Box,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useHistory, useParams, useLocation } from "react-router-dom";
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
import { getDocumentDetails, updateDocument } from "../../redux/actions/LcActions";
import { formatDateForFrontend, formatDateForBackend } from "../../utils/dateUtils";
import { usePIStyles } from "./lcStyle";

const LcDocAndPaymentUpdate = () => {
  const dispatch = useDispatch();
  const history = useHistory();
   const classes = usePIStyles(); // Custom styles hook
  const { id: lcDocId } = useParams();
  const location = useLocation();
  const { customerName } = location.state || {};

  const documentDetailsState = useSelector((state) => state.documentDetailsState);
  const { loading: documentLoading, error, document } = documentDetailsState;
// State for retaining lcIssuerBankId and lcIssuerBranchLocationId
const [issuerBankId, setIssuerBankId] = useState(null);
const [issuerBranchLocationId, setIssuerBranchLocationId] = useState(null);


  const [loading, setLoading] = useState(false);
  const [part2Visible, setPart2Visible] = useState(false);
  const [part3Visible, setPart3Visible] = useState(false);

  useEffect(() => {
    dispatch(getDocumentDetails(lcDocId));
  }, [dispatch, lcDocId]);

  useEffect(() => {
    if (document) {
      // Show Part-2 only if Part-1 is completed
      if (document.docPrepDate && document.docSumbitDate) {
        setPart2Visible(true);
      } else {
        setPart2Visible(false);
      }  
      // Show Part-3 only if Part-2 is completed
      if (document.docBenificerySubmissionDate && document.docCustomerAcceptedDate) {
        setPart3Visible(true);
      } else {
        setPart3Visible(false);
      }
      if (document) {
        // Store the specific fields in local state when details are loaded
        setIssuerBankId(document.lcIssuerBankId);
        setIssuerBranchLocationId(document.lcIssuerBranchLocationId);
      }
    }
  }, [document]);
  
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      piSystemId: document?.piSystemId,
      lcNumber: document?.lcNumber,
      lcDate: document?.lcDate ? formatDateForFrontend(document.lcDate) : "",
      docPrepDate: document?.docPrepDate ? formatDateForFrontend(document.docPrepDate) : "",
      docSumbitDate: document?.docSumbitDate ? formatDateForFrontend(document.docSumbitDate) : "",
      docBenificerySubmissionDate: document?.docBenificerySubmissionDate
        ? formatDateForFrontend(document.docBenificerySubmissionDate)
        : "",
      docCustomerAcceptedDate: document?.docCustomerAcceptedDate
        ? formatDateForFrontend(document.docCustomerAcceptedDate)
        : "",
      lcIbcNumber: document?.lcIbcNumber || "",
      lcIbcFbcReceiveDate: document?.lcIbcFbcReceiveDate
        ? formatDateForFrontend(document.lcIbcFbcReceiveDate)
        : "",
      bankAcceptanceDate: document?.bankAcceptanceDate
        ? formatDateForFrontend(document.bankAcceptanceDate)
        : "",
      maturityDate: document?.maturityDate ? formatDateForFrontend(document.maturityDate) : "",
      revisedMaturityDate: document?.revisedMaturityDate
        ? formatDateForFrontend(document.revisedMaturityDate)
        : "",
    },
    onSubmit: async (values) => {
      setLoading(true);
  
      const payload = {
        lcDocId,
        piSystemId: values.piSystemId,
        lcNumber: values.lcNumber,
        lcIssuerBankId: issuerBankId,
        lcIssuerBranchLocationId: issuerBranchLocationId,
        lcDate: formatDateForBackend(values.lcDate),
        docPrepDate: values.docPrepDate ? formatDateForBackend(values.docPrepDate) : null,
        docSumbitDate: values.docSumbitDate ? formatDateForBackend(values.docSumbitDate) : null,
        docBenificerySubmissionDate: values.docBenificerySubmissionDate
          ? formatDateForBackend(values.docBenificerySubmissionDate)
          : null,
        docCustomerAcceptedDate: values.docCustomerAcceptedDate
          ? formatDateForBackend(values.docCustomerAcceptedDate)
          : null,
        lcIbcNumber: values.lcIbcNumber || null,
        lcIbcFbcReceiveDate: values.lcIbcFbcReceiveDate
          ? formatDateForBackend(values.lcIbcFbcReceiveDate)
          : null,
        bankAcceptanceDate: values.bankAcceptanceDate
          ? formatDateForBackend(values.bankAcceptanceDate)
          : null,
        maturityDate: values.maturityDate ? formatDateForBackend(values.maturityDate) : null,
        revisedMaturityDate: values.revisedMaturityDate
          ? formatDateForBackend(values.revisedMaturityDate)
          : null,
      };
      try {
        const response = await dispatch(updateDocument(payload));
        setLoading(false);
        if (response?.statusCode === 200 || response?.statusCode === 205) {
          history.push("/lctracking/documentactionlist");
        } else {
          alert(response?.message || "Update failed. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        alert("An unexpected error occurred. Please try again later.");
      }
    },
  });

  return (
    <div>
      {/* Menu and Page Header */}
      <Menu />
      <PageHeader title="Update LC Document" />
  
      <Paper className={classes.pageContent}>
        {documentLoading ? (
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress color="primary" />
          </Box>
        ) : error ? (
          <Box display="flex" justifyContent="center" p={5}>
            <Typography color="error" variant="h6">
              Error: {error}
            </Typography>
          </Box>
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {/* Header Section */}
            <Box p={3} mb={4} borderRadius={2} border={1} borderColor="grey.300">
              <Typography variant="h6" gutterBottom>
                Document Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>LC Document ID: {lcDocId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>Customer Name: {customerName || "N/A"}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>PI System ID: {formik.values.piSystemId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>LC Number: {formik.values.lcNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>LC Date: {formik.values.lcDate}</Typography>
                </Grid>
              </Grid>
            </Box>
  
            {/* Part-1 Fields */}
            <Typography variant="h6" gutterBottom>
              Part-1 Fields
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
              <TextField
                fullWidth
                label="Document Preparation Date"
                type="date"
                name="docPrepDate"
                value={formik.values.docPrepDate}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
              />

              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Document Submission Date"
                  type="date"
                  name="docSumbitDate"
                  value={formik.values.docSumbitDate}
                  onChange={formik.handleChange}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
  
            {/* Part-2 Fields */}
            {part2Visible && (
              <>
                <Box mt={4} />
                <Typography variant="h6" gutterBottom>
                  Part-2 Fields
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Beneficiary Submission Date"
                      type="date"
                      name="docBenificerySubmissionDate"
                      value={formik.values.docBenificerySubmissionDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Customer Accepted Date"
                      type="date"
                      name="docCustomerAcceptedDate"
                      value={formik.values.docCustomerAcceptedDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
  
            {/* Part-3 Fields */}
            {part3Visible && (
              <>
                <Box mt={4} />
                <Typography variant="h6" gutterBottom>
                  Part-3 Fields
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="IBC Number"
                      name="lcIbcNumber"
                      value={formik.values.lcIbcNumber}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="IBC/FBC Receive Date"
                      type="date"
                      name="lcIbcFbcReceiveDate"
                      value={formik.values.lcIbcFbcReceiveDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Bank Acceptance Date"
                      type="date"
                      name="bankAcceptanceDate"
                      value={formik.values.bankAcceptanceDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Maturity Date"
                      type="date"
                      name="maturityDate"
                      value={formik.values.maturityDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Revised Maturity Date"
                      type="date"
                      name="revisedMaturityDate"
                      value={formik.values.revisedMaturityDate}
                      onChange={formik.handleChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
  
            {/* Submit and Back Buttons */}
            <Grid container spacing={3} mt={4}>
              <Grid item xs={12}>
                <MuiButton
                  size="large"
                  type="submit"
                  disabled={loading}
                  endIcon={loading ? <CircularProgress size="1rem" /> : null}
                >
                  {loading ? "Updating..." : "Update LC Document"}
                </MuiButton>
                <MuiButton
                  size="large"
                  variant="contained"
                  sx={{ ml: 2 }}
                  onClick={() => history.push("/lctracking/documentactionlist")}
                >
                  Back to List
                </MuiButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </div>
  );
  

};

export default LcDocAndPaymentUpdate;
