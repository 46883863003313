// Importing React and necessary hooks
import React, { useState, useEffect } from "react";
// Importing Redux hooks for state management
import { useDispatch, useSelector } from "react-redux";
// Importing Formik for form handling
import { useFormik } from "formik";
// Importing MUI components for UI elements
import {
  Grid,
  Paper,
  Button as MuiButton,
  CircularProgress,
  TextField,
  Typography,
  Box,
} from "@mui/material";
// Importing route handling hook
import { useHistory, useLocation } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
// Importing additional UI components
import Menu from "../../core/Menu";
import PageHeader from "../../core/PageHeader";
// Importing Redux actions for API calls
import { createDocument } from "../../redux/actions/LcActions";
import { listIssuerBank } from "../../redux/actions/chequeActions";
import { listLocationsAction } from "../../redux/actions/locationAction";
// Importing styling
import { usePIStyles } from "./lcStyle";

const LcDocAndPaymentCreate = () => {
  const classes = usePIStyles(); // Custom styles hook
  const dispatch = useDispatch(); // Redux dispatch function
  const history = useHistory(); // Hook for navigation
  const location = useLocation();
  const { piSystemId, piNumber, piDate, customerName, customerAddress } = location.state || {}; // Get values from state

  // Local state for loading indicator
  const [loading, setLoading] = useState(false);

  // Selecting data from Redux store
  const issuerBankListState = useSelector((state) => state.issuerBankList || {});
  const locationListState = useSelector((state) => state.locationList || {});

  // Extracting data from Redux state
  const banks = issuerBankListState.issuerbanks || [];
  const locations = locationListState.locations || [];

  // Checking if all data has loaded before rendering form
  const dataLoaded = banks.length > 0 && locations.length > 0;

  // Fetching necessary data on component mount
  useEffect(() => {
    dispatch(listIssuerBank()); // Fetch issuer banks
    dispatch(listLocationsAction()); // Fetch locations based on banks
  }, [dispatch]);

  // Formik setup for form handling and validation
  const formik = useFormik({
    initialValues: {
      lcNumber: "",
      lcIssuerBankId: null,
      lcIssuerBranchLocationId: null,
      lcDate: new Date().toISOString().split("T")[0], // Default to today's date
      piSystemId: piSystemId || null, // Pre-filled with passed piSystemId
    },
    onSubmit: async (values) => {
      setLoading(true);
      const payload = {
        ...values,
        lcIssuerBankId: values.lcIssuerBankId?.id || null,
        lcIssuerBranchLocationId: values.lcIssuerBranchLocationId?.locationId || null,
      };

      const response = await dispatch(createDocument(payload));
      setLoading(false);

      if (response?.statusCode === 201) {
        history.push("/lctracking/listlcanddoc"); // Redirect to document list after successful creation
      } else {
        console.error("Error creating LC document:", response?.message);
      }
    },
  });

  return (
    <div>
      {/* Menu and Page Header */}
      <Menu />
      <PageHeader title="Create LC Document" />

      <Paper className={classes.pageContent}>
        {loading || !dataLoaded ? (
          <CircularProgress />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            {/* Header Section */}
            <Box p={3} mb={4} borderRadius={2} border={1} borderColor="grey.300">
              <Typography variant="h6" gutterBottom>
                PI Information
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography>PI System ID: {piSystemId}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>PI Number: {piNumber}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>PI Date: {piDate}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography>Customer Name: {customerName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>Customer Address: {customerAddress}</Typography>
                </Grid>
              </Grid>
            </Box>

            {/* Form Section */}
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="LC Number"
                  name="lcNumber"
                  value={formik.values.lcNumber}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                  error={formik.touched.lcNumber && Boolean(formik.errors.lcNumber)}
                  helperText={formik.touched.lcNumber ? formik.errors.lcNumber : ""}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  fullWidth
                  type="date"
                  label="LC Date"
                  name="lcDate"
                  value={formik.values.lcDate}
                  onChange={formik.handleChange}
                  sx={{ mb: 3 }}
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="lcIssuerBankId"
                  name="lcIssuerBankId"
                  value={formik.values.lcIssuerBankId}
                  onChange={(event, value) => formik.setFieldValue("lcIssuerBankId", value || null)}
                  options={banks}
                  getOptionLabel={(option) => option.label || ""}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Issuer Bank"
                      error={formik.touched.lcIssuerBankId && Boolean(formik.errors.lcIssuerBankId)}
                      helperText={formik.touched.lcIssuerBankId ? formik.errors.lcIssuerBankId : ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="lcIssuerBranchLocationId"
                  name="lcIssuerBranchLocationId"
                  value={formik.values.lcIssuerBranchLocationId}
                  onChange={(event, value) =>
                    formik.setFieldValue("lcIssuerBranchLocationId", value || null)
                  }
                  options={locations}
                  getOptionLabel={(option) => option.locationName || ""}
                  isOptionEqualToValue={(option, value) => option.locationId === value?.locationId}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Issuer Branch Location"
                      error={
                        formik.touched.lcIssuerBranchLocationId &&
                        Boolean(formik.errors.lcIssuerBranchLocationId)
                      }
                      helperText={
                        formik.touched.lcIssuerBranchLocationId
                          ? formik.errors.lcIssuerBranchLocationId
                          : ""
                      }
                    />
                  )}
                />
              </Grid>

              {/* Submit and Back Buttons */}
              <Grid item xs={12}>
                <MuiButton
                  size="large"
                  type="submit"
                  disabled={loading}
                  endIcon={loading ? <CircularProgress size="1rem" /> : null}
                >
                  {loading ? "Submitting..." : "Create LC"}
                </MuiButton>
                <MuiButton
                  size="large"
                  variant="contained"
                  onClick={() => history.push("/lctracking/listlcanddoc")}
                  sx={{ ml: 2 }}
                >
                  Back to List
                </MuiButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Paper>
    </div>
  );
};

// Exporting the LcDocAndPaymentCreate component
export default LcDocAndPaymentCreate;
