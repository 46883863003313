// Proforma Invoice Constants
export const LC_PROFORMA_INVOICE_LIST_REQUEST = "LC_PROFORMA_INVOICE_LIST_REQUEST";
export const LC_PROFORMA_INVOICE_LIST_SUCCESS = "LC_PROFORMA_INVOICE_LIST_SUCCESS";
export const LC_PROFORMA_INVOICE_LIST_FAIL = "LC_PROFORMA_INVOICE_LIST_FAIL";

export const LC_PROFORMA_INVOICE_DETAILS_REQUEST = "LC_PROFORMA_INVOICE_DETAILS_REQUEST";
export const LC_PROFORMA_INVOICE_DETAILS_SUCCESS = "LC_PROFORMA_INVOICE_DETAILS_SUCCESS";
export const LC_PROFORMA_INVOICE_DETAILS_FAIL = "LC_PROFORMA_INVOICE_DETAILS_FAIL";
export const LC_PROFORMA_INVOICE_DETAILS_RESET = "LC_PROFORMA_INVOICE_DETAILS_RESET";

export const LC_PROFORMA_INVOICE_CREATE_REQUEST = "LC_PROFORMA_INVOICE_CREATE_REQUEST";
export const LC_PROFORMA_INVOICE_CREATE_SUCCESS = "LC_PROFORMA_INVOICE_CREATE_SUCCESS";
export const LC_PROFORMA_INVOICE_CREATE_FAIL = "LC_PROFORMA_INVOICE_CREATE_FAIL";
export const LC_PROFORMA_INVOICE_CREATE_RESET = "LC_PROFORMA_INVOICE_CREATE_RESET";

export const LC_PROFORMA_INVOICE_UPDATE_REQUEST = "LC_PROFORMA_INVOICE_UPDATE_REQUEST";
export const LC_PROFORMA_INVOICE_UPDATE_SUCCESS = "LC_PROFORMA_INVOICE_UPDATE_SUCCESS";
export const LC_PROFORMA_INVOICE_UPDATE_FAIL = "LC_PROFORMA_INVOICE_UPDATE_FAIL";
export const LC_PROFORMA_INVOICE_UPDATE_RESET = "LC_PROFORMA_INVOICE_UPDATE_RESET";

export const LC_PROFORMA_INVOICE_DELETE_REQUEST = "LC_PROFORMA_INVOICE_DELETE_REQUEST";
export const LC_PROFORMA_INVOICE_DELETE_SUCCESS = "LC_PROFORMA_INVOICE_DELETE_SUCCESS";
export const LC_PROFORMA_INVOICE_DELETE_FAIL = "LC_PROFORMA_INVOICE_DELETE_FAIL";
export const LC_PROFORMA_INVOICE_DELETE_RESET = "LC_PROFORMA_INVOICE_DELETE_RESET";

// LC Lifecycle Constants
export const LC_LIFECYCLE_LIST_REQUEST = "LC_LIFECYCLE_LIST_REQUEST";
export const LC_LIFECYCLE_LIST_SUCCESS = "LC_LIFECYCLE_LIST_SUCCESS";
export const LC_LIFECYCLE_LIST_FAIL = "LC_LIFECYCLE_LIST_FAIL";


// Payment Terms Constants
export const LC_PAYMENT_TERMS_LIST_REQUEST = "LC_PAYMENT_TERMS_LIST_REQUEST";
export const LC_PAYMENT_TERMS_LIST_SUCCESS = "LC_PAYMENT_TERMS_LIST_SUCCESS";
export const LC_PAYMENT_TERMS_LIST_FAIL = "LC_PAYMENT_TERMS_LIST_FAIL";

// Document/Payment Information Constants
export const LC_DOCUMENT_LIST_REQUEST = "LC_DOCUMENT_LIST_REQUEST";
export const LC_DOCUMENT_LIST_SUCCESS = "LC_DOCUMENT_LIST_SUCCESS";
export const LC_DOCUMENT_LIST_FAIL = "LC_DOCUMENT_LIST_FAIL";

export const LC_DOCUMENT_DETAILS_REQUEST = "LC_DOCUMENT_DETAILS_REQUEST";
export const LC_DOCUMENT_DETAILS_SUCCESS = "LC_DOCUMENT_DETAILS_SUCCESS";
export const LC_DOCUMENT_DETAILS_FAIL = "LC_DOCUMENT_DETAILS_FAIL";
export const LC_DOCUMENT_DETAILS_RESET = "LC_DOCUMENT_DETAILS_RESET";

export const LC_DOCUMENT_CREATE_REQUEST = "LC_DOCUMENT_CREATE_REQUEST";
export const LC_DOCUMENT_CREATE_SUCCESS = "LC_DOCUMENT_CREATE_SUCCESS";
export const LC_DOCUMENT_CREATE_FAIL = "LC_DOCUMENT_CREATE_FAIL";
export const LC_DOCUMENT_CREATE_RESET = "LC_DOCUMENT_CREATE_RESET";

export const LC_DOCUMENT_UPDATE_REQUEST = "LC_DOCUMENT_UPDATE_REQUEST";
export const LC_DOCUMENT_UPDATE_SUCCESS = "LC_DOCUMENT_UPDATE_SUCCESS";
export const LC_DOCUMENT_UPDATE_FAIL = "LC_DOCUMENT_UPDATE_FAIL";
export const LC_DOCUMENT_UPDATE_RESET = "LC_DOCUMENT_UPDATE_RESET";

export const LC_DOCUMENT_DELETE_REQUEST = "LC_DOCUMENT_DELETE_REQUEST";
export const LC_DOCUMENT_DELETE_SUCCESS = "LC_DOCUMENT_DELETE_SUCCESS";
export const LC_DOCUMENT_DELETE_FAIL = "LC_DOCUMENT_DELETE_FAIL";
export const LC_DOCUMENT_DELETE_RESET = "LC_DOCUMENT_DELETE_RESET";

export const LC_PROFORMA_INVOICE_ESCALATE_REQUEST = "LC_PROFORMA_INVOICE_ESCALATE_REQUEST";
export const LC_PROFORMA_INVOICE_ESCALATE_SUCCESS = "LC_PROFORMA_INVOICE_ESCALATE_SUCCESS";
export const LC_PROFORMA_INVOICE_ESCALATE_FAIL = "LC_PROFORMA_INVOICE_ESCALATE_FAIL";
export const LC_PROFORMA_INVOICE_ESCALATE_RESET = "LC_PROFORMA_INVOICE_ESCALATE_RESET";

export const LC_GET_LIST_BY_STATUS_REQUEST = "LC_GET_LIST_BY_STATUS_REQUEST";
export const LC_GET_LIST_BY_STATUS_SUCCESS = "LC_GET_LIST_BY_STATUS_SUCCESS";
export const LC_GET_LIST_BY_STATUS_FAIL = "LC_GET_LIST_BY_STATUS_FAIL";

