 import ExcelJs from "exceljs";

// export const exportToExcelExcelJsSummary = (
//   data = [],
//   headerKeyMapping,
//   file_name = "Report",
//   excel_header = "Report",
//   customerName = "Customer" // New parameter for customerName
// ) => {
//   let sheetName = `${file_name}.xlsx`;
//   let headerName = "RequestsList";

//   let workbook = new ExcelJs.Workbook();
//   let sheet = workbook.addWorksheet(sheetName, {
//     views: [{ showGridLines: true }],
//   });

//   // Ensure default row alignment
//   sheet.properties.defaultRowAlignment = {
//     vertical: "bottom",
//     horizontal: "left", // Set default horizontal alignment to left
//   };

//   let updatedTitle = [];

//   for (const columnDataKey in headerKeyMapping) {
//     updatedTitle.push({ name: headerKeyMapping[columnDataKey] });
//   }

//   // Add title
//   sheet.addRow([
//     `Loan Summary Report For ${customerName}`,
//   ]);
//   sheet.getCell("A1").font = { size: 20, bold: true };
//   sheet.addRow([]); // Empty row for separation

//   // Remove the calculation rows here

//   // Set the width of column A to 25
//   sheet.getColumn('A').width = 25;

//   // Add the table header starting after the empty row
//   const tableStartRow = 3; // Changed to reflect the removed calculation rows
//   sheet.addTable({
//     name: headerName,
//     ref: `A${tableStartRow}`,
//     headerRow: true,
//     totalsRow: false,
//     style: {
//       theme: "TableStyleMedium2",
//       showRowStripes: false,
//       width: 200,
//       border: {
//         top: { style: "double", color: { argb: "FF00FF00" } },
//         left: { style: "double", color: { argb: "FF00FF00" } },
//         bottom: { style: "double", color: { argb: "FF00FF00" } },
//         right: { style: "double", color: { argb: "FF00FF00" } },
//       },
//     },
//     columns: updatedTitle ? updatedTitle : [{ name: "" }],
//     rows: data?.map((e) => {
//       let arr = [];
//       for (let i in e) {
//         if (headerKeyMapping[i]) {
//           arr.push(e[i]);
//         }
//       }
//       return arr;
//     }),
//   });

//   // Adjust column width based on content
//   sheet.columns = updatedTitle.map((title, index) => {
//     const col = sheet.getColumn(index + 1);
//     col.width = Math.max(
//       title.name.length,
//       ...data.map(
//         (row) =>
//           row[Object.keys(headerKeyMapping)[index]]?.toString().length || 10
//       )
//     ) + 2; // Adding some extra space for better readability
//     return col;
//   });

//   const table = sheet.getTable(headerName);
//   for (let i = 0; i < table.table.columns.length; i++) {
//     sheet.getCell(`${String.fromCharCode(65 + i)}${tableStartRow}`).font = { size: 12 };
//     sheet.getCell(`${String.fromCharCode(65 + i)}${tableStartRow}`).fill = {
//       type: "pattern",
//       pattern: "solid",
//       fgColor: { argb: "c5d9f1" },
//     };

//     for (let j = 0; j < table.table.rows.length; j++) {
//       let rowCell = sheet.getCell(`${String.fromCharCode(65 + i)}${j + tableStartRow + 1}`);
//       rowCell.alignment = {
//         wrapText: true,
//         horizontal: "left", // Ensure each cell is left-aligned
//       };
//       rowCell.border = {
//         bottom: {
//           style: "thin",
//           color: { argb: "a6a6a6" },
//         },
//       };
//     }
//   }
//   table.commit();

//   const writeFile = (fileName, content) => {
//     const link = document.createElement("a");
//     const blob = new Blob([content], {
//       type: "application/vnd.ms-excel;charset=utf-8;",
//     });
//     link.download = fileName;
//     link.href = URL.createObjectURL(blob);
//     link.click();
//   };

//   workbook.xlsx.writeBuffer().then((buffer) => {
//     writeFile(sheetName, buffer);
//   });
// };

export const exportToExcelExcelJsSummary = (
  data = [],
  headerKeyMapping,
  file_name = "Report",
  excel_header = "Report",
  customerName = "Customer"
) => {
  let sheetName = `${file_name}.xlsx`;
  let headerName = "RequestsList";

  let workbook = new ExcelJs.Workbook();
  let sheet = workbook.addWorksheet(sheetName, {
    views: [{ showGridLines: true }],
  });

  sheet.properties.defaultRowAlignment = {
    vertical: "bottom",
    horizontal: "left",
  };

  const updatedHeaderKeyMapping = {
    customerName: "Customer Name",
    productName: "Product Name",
    loanReceivedQty: "Loan Received Quantity",
    loanGivenQty: "Loan Given Quantity",
    balanceQty: "Balance Quantity",
  };

  let updatedTitle = [];

  for (const columnDataKey in updatedHeaderKeyMapping) {
    updatedTitle.push({ name: updatedHeaderKeyMapping[columnDataKey] });
  }

  sheet.addRow([`Loan Summary Report For ${customerName}`]);
  sheet.getCell("A1").font = { size: 20, bold: true };
  sheet.addRow([]);

  const tableStartRow = 3;
  sheet.addTable({
    name: headerName,
    ref: `A${tableStartRow}`,
    headerRow: true,
    totalsRow: false,
    style: {
      theme: "TableStyleMedium2",
      showRowStripes: false,
    },
    columns: updatedTitle,
    rows: data.map((e) => [
      e.customerName || "", // Ensure customerName is correctly included
      e.productName || "",
      e.loanReceivedQty || 0,
      e.loanGivenQty || 0,
      e.balanceQty || 0,
    ]),
  });

  sheet.columns = updatedTitle.map((title, index) => {
    const col = sheet.getColumn(index + 1);
    col.width = Math.max(
      title.name.length,
      ...data.map((row) => {
        const value = Object.values(row)[index];
        return value ? value.toString().length : 10;
      })
    ) + 2;
    return col;
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.ms-excel;charset=utf-8;",
    });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `${file_name}.xlsx`;
    link.click();
  });
};

